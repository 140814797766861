// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { Dispatch, TransitionEvent, useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Layout from 'antd/lib/layout';
import { AutoComplete } from 'antd';
import { CombinedState } from 'reducers';
import { DimensionType } from 'cvat-core-wrapper';
import LabelsList from 'components/annotation-page/standard-workspace/objects-side-bar/labels-list';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import AppearanceBlock from 'components/annotation-page/appearance-block';
import IssuesListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/issues-list';
import TaskItemComponent from 'components/tasks-page/task-item';
import { Task } from 'reducers/interfaces';
import { useEffect } from 'react';
// Added to fetch env variable of Sanbox URL
import { getSandboxURL } from 'utils/environment';

// Sandbox Base URL initialized
const BASE_URL = getSandboxURL();

interface OwnProps {
    objectsList: JSX.Element;
    task: Task
}
interface Props {
    userName: any,
}
interface StateToProps {
    sidebarCollapsed: boolean;
    jobInstance: any;
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            job: { instance: jobInstance },
        },
    } = state;

    return {
        sidebarCollapsed,
        jobInstance,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        sidebarCollapsed, collapseSidebar, objectsList, jobInstance,
    } = props;
    const [name, setName] = useState("")
    const [quality_tag, setquality_tag] = useState()
    const collapse = (): void => {
        const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
        const listener = (event: TransitionEvent): void => {
            if (event.target && event.propertyName === 'width' && event.target === collapser) {
                window.dispatchEvent(new Event('resize'));
                (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
            }
        };

        if (collapser) {
            (collapser as HTMLElement).addEventListener('transitionend', listener as any);
        }

        collapseSidebar();
    };
    
    const taskName = localStorage.getItem('taskName');
    useEffect(() => {
        fetch(`${BASE_URL}get_filtered_data/`, {  // Addition of Dynamic Sandbox URL
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "file_id_value": taskName,
                "file_name_value": '',
                "max_value_value": '',
                "max_value_column_value": '',
                "modified_on_value": '',
                "processing_stage_value": '',
                "created_on_value": ''
            })

        })
            .then((response) => response.json())
            .then((json) => {

                return setquality_tag(json[0].annotation_quality_tag);
                // this.setState({ qualitytag: json[0].annotation_quality_tag });
            })

    });
    const { Option } = AutoComplete;
    let Quality =
        [{
            "key": "poor_quality",
            "value": "Poor Quality"
        },
        {
            "key": "incorrect_view",
            "value": "Incorrect View"
        },
        {
            "key": "wrong_orientation",
            "value": "Wrong Orientation"
        }]
    const onQualityTagChange = (event: any, name: any) => {
        debugger
        setName(name);
        setquality_tag(event)
        fetch(`${BASE_URL}saveQualityTag/`, { // Addition of Dynamic Sandbox URL
            method: "POST",
            // headers: {
            //   Authorization: `Bearer ${accessToken}`
            // } 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "file_id": name,
                "tag": event,
            })

        })
            .then((response) => console.log(response))
            .catch(error => {
            })
    }
    const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIMENSION_2D : true;

    return (
        <Layout.Sider
            className='cvat-objects-sidebar'
            theme='light'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            {/* eslint-disable-next-line */}
            <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span>

            <Tabs type='card' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>Objects</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>Labels</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>Quality</Text>} key='quality'>
                    <Text strong > Quality Tag</Text>
                    <AutoComplete
                        style={{
                            width: 200,
                        }}
                        value={quality_tag}
                        onChange={(event: any) => onQualityTagChange(event, taskName)}
                        placeholder="input here"
                    >
                        {Quality.map((qual) => (

                            <Option key={qual.key} value={qual.value}>
                                {qual.value}
                            </Option>
                        ))}
                    </AutoComplete>
                </Tabs.TabPane>

                {is2D ? (
                    <Tabs.TabPane tab={<Text strong>Issues</Text>} key='issues'>
                        <IssuesListComponent />
                    </Tabs.TabPane>
                ) : null}
            </Tabs>

            {!sidebarCollapsed && <AppearanceBlock />}
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
function value(value: any): [any, any] {
    throw new Error('Function not implemented.');
}